<template>
  <b-row>
    <b-col cols="12" class="d-flex justify-content-between">
      <p class="form-title mb-3">Dados da guia</p>
    </b-col>

    <b-col :cols="columnCount" v-if="fields.guide_issue_date">
      <b-form-group>
        <label for="guide_issue_date">Data emissão da guia</label>
        <date-picker
          v-model="form.guide_issue_date"
          format="DD/MM/YYYY"
          placeholder="DD/MM/AAAA"
          :lang="langDatePicker"
          :clearable="false"
          :disabled="readonly"
          class="full"
          autocomplete="off"
        />
      </b-form-group>
    </b-col>

    <b-col :cols="columnCount" v-if="fields.tiss_invoice_id">
      <b-form-group>
        <label for="tiss_invoice_id">
          Fatura relacionada
        </label>
        <multiselect
          :value="relatedInvoice"
          id="tiss_invoice_id"
          :options="relatedInvoices"
          label="label"
          :allow-empty="true"
          :option-height="40"
          :showLabels="false"
          :searchable="true"
          :internal-search="true"
          placeholder="Selecionar"
          class="with-border"
          @select="selectInvoice"
          :disabled="Boolean(tissBatchId || readonly) || relatedInvoices.find((invoice) => invoice.value === form.tiss_invoice_id)?.$isDisabled"
        >
          <template slot="caret">
            <div class="chevron">
              <ChevronDown />
            </div>
          </template>
          <template slot="noResult">Nenhum resultado encontrado</template>
        </multiselect>
      </b-form-group>
    </b-col>

    <b-col :cols="columnCount" v-if="fields.request_id">
      <b-form-group>
        <label for="request_id">ID da solicitação</label>
        <b-form-input
          placeholder="Descrever"
          autocomplete="off"
          class="form-control"
          v-model="form.request_id"
          :readonly="readonly"
        />
      </b-form-group>
    </b-col>
    <b-col :cols="columnCount" v-if="fields.situation">
      <b-form-group>
        <label for="situation">Situação da guia</label>
          <StatusButton 
            width="100%"
            v-model="form.situation"
            :disabled="readonly || !userHasPermission('FatTissFat4') || this.disabledSituation"
            :statuses="guideSituationOptions"
          />
        <div v-if="validated && !form.situation" class="custom-invalid-feedback">
          Campo obrigatório
        </div>
      </b-form-group>
    </b-col>
    <b-col :cols="columnCount" v-if="fields.onlending">
      <b-form-group>
        <div>
          <label for="">Profissional Repasse Médico</label>
          <span id="info-badge">!</span>
          <b-tooltip target="info-badge" triggers="hover" custom-class="custom-onlending-tooltip">
            <span style="font-size: 14px">
              <strong>Defina o profissional para repasse médico</strong><br>
            </span>
            <span style="font-size: 12px">
              Obs.: Se a guia for gerada a partir de um agendamento, será definido o profissional do agendamento.
            </span>
          </b-tooltip>
        </div>
          <BeneficiarySelect
            v-model="form.onlending_professional"
            :value="form.onlending_professional"
            :onlendingRule="true"
          />
      </b-form-group>
    </b-col>
    <b-col cols="12">
      <hr id="hr1" />
    </b-col>
  </b-row>
</template>

<script>
import { userHasPermission, getCurrentClinic } from '@/utils/localStorageManager'
import { TissInvoice, TissGuide, guideModalSituationOptions } from '@/modules/tiss/manageGuides/utils/statuses'

export default {
  name: 'GuideDataModule',
  components: {
    StatusButton: () => import('@/modules/tiss/manageGuides/components/StatusButton'),
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    BeneficiarySelect: () => import('@/components/General/BeneficiarySelect'),
  },
  props: {
    form: Object,
    fields: Object,
    validated: Boolean,
    healthPlanId: String,
    tissBatchId: String,
    readonly: {
      type: Boolean,
      default: false
    },
    tissGuide: Object,
  },
  async created() {

    if(this.situation?.label === 'Cancelada'){
      this.disabledSituation = true
    }
    if (this.fields.tiss_invoice_id) await this.getInvoicingNumbers()
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      relatedInvoices: [],
      relatedInvoice: null,
      situation: null,
      disabledSituation: null,
      guideSituationOptions: guideModalSituationOptions,
      langDatePicker: {
        formatLocale: {
          weekdaysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']
        }
      },
    }
  },
  computed: {
    expensable() {
      return this.tissGuide?.guide?.expensables?.length ? this.tissGuide?.guide?.expensables[0]?.guide?.id : null
    },

    columnCount(){
      let fieldCount = 0;
      if(Object.entries(this.fields).length > 0){
        Object.entries(this.fields).map(el => {
          if(el) fieldCount++;
        })
        if(fieldCount > 0){
          return Math.round(12 / fieldCount);
        } else {
          return 3;
        }
      } else {
        return 3;
      }
    }
  },
  methods: {
    userHasPermission,
    async getInvoicingNumbers() {
      const isLoading = this.$loading.show();

      try {
        const { data } = await this.api.getInvoicingTissNumbers(this.healthPlanId, this.clinic.id, true);
        this.relatedInvoices = data
          .reduce((acc, invoice) => {
            acc.push({
              label: invoice.number,
              value: invoice.id,
              $isDisabled: invoice.status !== TissInvoice.INVOICE_STATUS_OPEN,
            })
            return acc
            }, [{ label: 'Nenhuma', value: null }]
          )
          .sort((current, previous) => (previous.label - current.label))

        this.relatedInvoice = this.relatedInvoices.find(invoice => invoice?.value === this.form.tiss_invoice_id)

      } catch (err) {
        this.$toast.error(err.message);
      } finally {
        isLoading.hide();
      }
    },
    selectInvoice(option) {
      this.relatedInvoice = option
      this.form.tiss_invoice_id = option.value
    },
    async getBeneficiaryByPersonId(id){
        return await this.api.getBeneficiaryByPersonId(id).then(({data}) => {
          if(Object.keys(data).length > 0){
            return data;
          } else {
            return null;
          }
        });
    }
  },
  watch: {
    'tiss_invoice_id': {
      handler(newValue) {
        this.relatedInvoice = this.relatedInvoices.find(option => option.value === newValue.tiss_invoice_id)
        this.form.tiss_invoice_id = this.relatedInvoice.value
      },
      deep: true
    },
    tissGuide: {
      async handler(newValue) {
        if(newValue.guide?.onlending_professional_id){
          await this.api.getBeneficiaryById(newValue.guide.onlending_professional_id).then(({data}) => {
            this.form.onlending_professional = {id: data.id, name: data.name};
          })
        }
        const beneficiary = await this.getBeneficiaryByPersonId(newValue.professional_id_fill);
        if(beneficiary){
          this.form.onlending_professional = {id: beneficiary.id, name: beneficiary.name};
        }
        if (!newValue?.id) {
          this.form.situation = TissGuide.GUIDE_SITUATION_IN_PREPARATION
        }
      },
      immediate: true,
      deep: true
    },
    'form.situation': {
      handler(newValue) {
        this.situation = this.guideSituationOptions.find(option => option.value === newValue)
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<style lang="scss">
  .button-text{
    margin-top: 2px !important;
    margin-left: -7px !important;
  }

  .eye-icon{
    fill: #305BF2;
    width: 20px !important;
    height: 18px !important;
    margin-right: 10px !important;
  }

  #info-badge{
    font-size: small;
    border: 2px solid lightgray;
    margin-left: 5px;
    padding: 1px 6px;
    border-radius: 20px;
    font-weight: bold;
    color: lightgray;
    cursor: pointer;
  }
</style>
